
    import {defineComponent, reactive} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {TextField} from "@/types/field";
    import {EmailValidationRule, FormValidation, RequiredValidationRule} from "@/types/validation";
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "ResetPassword",
        setup() {

            //  Notification plugin
            const notification = useNotification();

            //  Translation plugin
            const i18n = useTranslation();

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('email', 'auth.password.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            );

            //  Data object with account details
            const values = reactive({
                loading: false,
                email: '',
            });

            //  Reset method
            const reset = () => {
                form.setError('').submit().then(() => {
                    values.loading = true;
                    userStore.forgotPassword(values.email).then(() => {
                        values.loading = false;
                        notification.success(i18n.translate('auth.password.success.title'), i18n.translate('auth.password.success.message'))
                    }).catch(error => {
                        values.loading = false;
                        form.setError(error);
                    });
                });
            }

            return {
                reset,
                form,
                values
            }
        }
    })
