<template>
    <div class="mx-auto w-full max-w-sm">
        <div>
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                {{ $translate('auth.password.title') }}
            </h2>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
                {{ $translate('auth.password.or') }}
                <router-link :to="{name: 'login'}" tag="a"
                             class="font-medium text-custom-600 hover:text-custom-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    {{ $translate('auth.password.signin') }}
                </router-link>
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 space-y-6">
                <DataForm :form="form" v-model="values"/>
                <span class="block w-full rounded-md shadow-sm">
                    <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500"
                            @click="reset">
                            <span class="pr-2" v-if="values.loading"><i class="fal fa-spinner-third fa-spin"></i></span>
                        {{ $translate('auth.password.reset') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {TextField} from "@/types/field";
    import {EmailValidationRule, FormValidation, RequiredValidationRule} from "@/types/validation";
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "ResetPassword",
        setup() {

            //  Notification plugin
            const notification = useNotification();

            //  Translation plugin
            const i18n = useTranslation();

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('email', 'auth.password.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            );

            //  Data object with account details
            const values = reactive({
                loading: false,
                email: '',
            });

            //  Reset method
            const reset = () => {
                form.setError('').submit().then(() => {
                    values.loading = true;
                    userStore.forgotPassword(values.email).then(() => {
                        values.loading = false;
                        notification.success(i18n.translate('auth.password.success.title'), i18n.translate('auth.password.success.message'))
                    }).catch(error => {
                        values.loading = false;
                        form.setError(error);
                    });
                });
            }

            return {
                reset,
                form,
                values
            }
        }
    })
</script>